import React from 'react';
import { graphql } from 'gatsby';

import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import DefaultLayout from '../layout/DefaultLayout';
import { SliderButton, Popover } from '@typeform/embed-react';
import image from '../assets/images/variowelt-preview.png';
import * as styles from './gewinnspiel.module.css';

export default function GewinnspielPage({ data, location }) {
  const { pageData, siteData } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <div className={styles.page}>
        <div className={styles.container}>
          <div style={{ textAlign: 'center' }}>
            <img src={image} className={styles.image} />
          </div>
          <div style={{ textAlign: 'left', padding: '2em' }}>
            <h2 style={{ lineHeight: 1.5, textAlign: 'center', fontSize: '2em' }}>
              Am Gewinnspiel teilnehmen und mit etwas Glück den neuen FENDT 600 für 50 Stunden gewinnen!{' '}
            </h2>

            <div style={{ textAlign: 'center', margin: '3.5em 0' }}>
              <SliderButton
                id="THeSsnpE"
                className={styles.button}
                size={50}
                height={800}
                width={800}
                iframeProps={{ style: { width: 500 } }}
              >
                Jetzt teilnehmen!
              </SliderButton>
            </div>
            <div style={{ fontSize: 12, marginTop: '2em' }}>
              Teilnahmebedingungen und Rechtshinweis: Es werden nur vollständig ausgefüllte Formulare akzeptiert und die
              GewinnerIn wird nach dem Zufallsprinzip ermitteelt und von EZ AGRAR e.Gen. benachrichtigt.
              Teilnahmeberechtigt sind Presonen ab 18 Jahren. Die Umwandlung der Gewinne in Bargeld ist nicht möglich
              und es kann nur jeweils eine Maschine gewonnen werden. Mit der Teilnahme am Gewinnspiel geben Sie das
              Einverständnis, dass Ihre Kontaktdaten zum Zwecke der Gewinnermittlung und für EZ Agrar e. Gen.
              Kundeninformationen verwendet werden dürfen. Der Rechtsweg ist ausgeschlossen.
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export const GewinnspielEmbedSocialQuery = graphql`
  query GewinnspielEmbedSocialQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageImpressum {
      content {
        title
        pageID
        teaser
        text
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
  }
`;
